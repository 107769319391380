#homepage {
  width: 1168px;
  margin: 0 auto;
  padding: 24px 16px 60px 16px;
  min-height: calc(100vh - 220px);
}

#homepage > #main-nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 292px;
  background-color: #218cf7;
  border-radius: 8px;
  padding: 16px 24px 24px 24px;
  box-sizing: border-box;
}

/* self-study-container start */

#homepage > #main-nav-container > #self-study-container {
  width: 552px;
}

#homepage > #main-nav-container > #self-study-container > .design-line {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #218cf7 15%, #fff 50%, #218cf7 85%);
}

#homepage > #main-nav-container > #self-study-container > :last-of-type {
  width: 552px;
  height: 234px;
  background-color: #0873de;
  margin-top: 16px;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
}

#homepage > #main-nav-container > #self-study-container > :last-of-type > ul {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: flex-end;
}

#homepage
  > #main-nav-container
  > #self-study-container
  > div:last-of-type
  > ul:first-of-type
  > li:first-of-type {
  font-size: 18px;
  font-weight: bold;
}

#homepage
  > #main-nav-container
  > #self-study-container
  > div:last-of-type
  > ul:first-of-type
  > li:last-of-type
  > a {
  color: #fff;
}

#homepage
  > #main-nav-container
  > #self-study-container
  > div:last-of-type
  > div:nth-of-type(1) {
  width: 100%;
  height: 1px;
  background-color: #fff;
}

#homepage
  > #main-nav-container
  > #self-study-container
  > div:last-of-type
  > ul:last-of-type {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

#homepage
  > #main-nav-container
  > #self-study-container
  > div:last-of-type
  > ul:last-of-type
  > a {
  width: 100%;
}

#homepage
  > #main-nav-container
  > #self-study-container
  > div:last-of-type
  > ul:last-of-type
  > a
  > li {
  width: 100%;
  height: 44px;
  background-color: #fff;
  border-radius: 8px;
  color: black;
  padding: 0px 0px 0px 12px;
  line-height: 44px;
  box-sizing: border-box;
}

/* self-study-container end */

/* tranning-center-container start */
#homepage > #main-nav-container > #tranning-center-container {
  width: 552px;
}

#homepage > #main-nav-container > #tranning-center-container > .design-line {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #218cf7 15%, #fff 50%, #218cf7 85%);
}

#homepage > #main-nav-container > #tranning-center-container > :last-of-type {
  width: 552px;
  height: 234px;
  background-color: #0873de;
  margin-top: 16px;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
}

#homepage
  > #main-nav-container
  > #tranning-center-container
  > :last-of-type
  > ul {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: flex-end;
}

#homepage
  > #main-nav-container
  > #tranning-center-container
  > div:last-of-type
  > ul:first-of-type
  > li:first-of-type {
  font-size: 18px;
  font-weight: bold;
}

#homepage
  > #main-nav-container
  > #tranning-center-container
  > div:last-of-type
  > ul:first-of-type
  > li:last-of-type
  > a {
  color: #fff;
}

#homepage
  > #main-nav-container
  > #tranning-center-container
  > div:last-of-type
  > div:nth-of-type(1) {
  width: 100%;
  height: 1px;
  background-color: #fff;
}

#homepage
  > #main-nav-container
  > #tranning-center-container
  > div:last-of-type
  > ul:last-of-type {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

#homepage
  > #main-nav-container
  > #tranning-center-container
  > div:last-of-type
  > ul:last-of-type
  > a {
  width: 100%;
}

#homepage
  > #main-nav-container
  > #tranning-center-container
  > div:last-of-type
  > ul:last-of-type
  > a
  > li {
  width: 100%;
  height: 44px;
  background-color: #fff;
  border-radius: 8px;
  color: black;
  padding: 0px 0px 0px 12px;
  line-height: 44px;
  box-sizing: border-box;
}

/* tranning-center-container end*/

#academic-container {
  margin-top: 40px;
}

#academic-container > div{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

#academic-container > div:first-of-type > div{
  width: 32px;
  height: 2px;
  background-color: #0558AA;
}

#academic-container > div:first-of-type > p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

#academic-container > div:last-of-type {
  display: grid;
  grid-template-columns: 188px 188px 188px 188px 188px 188px;
  gap: 16px 8px;
}

/* ------------- media query start ------------------*/

@media (max-width: 480px) {
  #homepage {
    width: 100%;
    padding: 12px 8px 40px 8px;
    box-sizing: border-box;
  }

  #homepage > #main-nav-container {
    height: auto;
    padding: 12px 8px;
  }

  #homepage
    > #main-nav-container
    > #self-study-container
    > div:last-of-type
    > ul:last-of-type {
    display: none;
  }

  #homepage > #main-nav-container > #tranning-center-container > .design-line {
    display: none;
  }

  #homepage
    > #main-nav-container
    > #self-study-container
    > div:last-of-type
    > div:nth-of-type(1) {
    display: none;
  }

  #homepage
    > #main-nav-container
    > #tranning-center-container
    > div:last-of-type
    > div:nth-of-type(1) {
    display: none;
  }

  #homepage
    > #main-nav-container
    > #tranning-center-container
    > div:last-of-type
    > ul:last-of-type {
    display: none;
  }

  #homepage > #main-nav-container > #self-study-container > :last-of-type {
    height: auto;
  }

  #homepage > #main-nav-container > #tranning-center-container > :last-of-type {
    height: auto;
    margin-top: 8px;
  }

  #homepage > #main-nav-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  #homepage > #main-nav-container > #self-study-container {
    width: 100%;
  }

  #homepage > #main-nav-container > #self-study-container > :last-of-type {
    width: 100%;
  }

  #homepage > #main-nav-container > #tranning-center-container {
    width: 100%;
  }

  #homepage > #main-nav-container > #tranning-center-container > :last-of-type {
    width: 100%;
  }

  #homepage > #main-nav-container > #self-study-container > :last-of-type > ul {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 0px;
  }

  #homepage
    > #main-nav-container
    > #tranning-center-container
    > :last-of-type
    > ul {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 0px;
  }

  #academic-container > div:first-of-type > div{
    width: 32px;
    height: 2px;
    background-color: #0558AA;
  }
  
  #academic-container > div:first-of-type > p {
    font-size: 16px;
    margin-bottom: 16px;
  }

  #academic-container > div:last-of-type {
    grid-template-columns: 1fr 1fr;
  }

  #homepage
    > #main-nav-container
    > #self-study-container
    > div:last-of-type
    > ul:first-of-type
    > li:first-of-type,
  #homepage
    > #main-nav-container
    > #tranning-center-container
    > div:last-of-type
    > ul:first-of-type
    > li:first-of-type {
    font-size: 14px;
    line-height: 21px;
  }

  #homepage
    > #main-nav-container
    > #self-study-container
    > div:last-of-type
    > ul:first-of-type
    > li:last-of-type
    > a,
  #homepage
    > #main-nav-container
    > #tranning-center-container
    > div:last-of-type
    > ul:first-of-type
    > li:last-of-type
    > a {
    font-size: 12px;
  }

  #academic-container > p {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
