#main-series-post-list {
    width: 1168px;
    margin: 0 auto;
    padding: 24px 16px 60px 16px;
    min-height: calc(100vh - 220px);
}

#main-series-post-list > p{
    font-size: 24px;
    font-weight: bold;
    margin: 40px 0px 20px 0px;
    padding: 0px 16px;
    box-sizing: border-box;
}

#main-series-post-list > #series-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
}

#main-series-post-list > #title-container{
    width: 100%;
    min-height:240px;
    background: linear-gradient(to right, #218CF7, #29ADEF);
    margin-bottom: 24px;
    padding: 24px 16px;
    box-sizing: border-box;
}

#main-series-post-list > #title-container > p{
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 12px;
}

#main-series-post-list > #title-container > div{
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #fff;
}

@media (max-width: 480px) {

    #main-series-post-list{
        width: 100%;
        padding: 12px 8px 40px 8px;
        box-sizing: border-box;
    }

    #main-series-post-list > p{
        font-size: 16px;
        margin: 24px 0px 12px 0px;
    }

    #main-series-post-list > #series-container{
        width: 100%;
    }

    #main-series-post-list > #title-container{
        min-height:160px;
        margin-bottom: 16px;
        padding: 16px 16px;
    }
    
    #main-series-post-list > #title-container > p{
        font-size: 16px;
        margin-bottom: 8px;
    }
    
    #main-series-post-list > #title-container > div{
        font-size: 12px;
        line-height: 18px;
    }
}