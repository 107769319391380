#main-series-post-detail {
    width: 768px;
    margin: 0 auto;
    padding: 24px 16px 60px 16px;
    min-height: calc(100vh - 220px);
}

#main-series-post-detail > p{
    font-size: 24px;
    font-weight: bold;
    margin: 40px 0px 20px 0px;
    box-sizing: border-box;
}

#main-series-post-detail > #content-container{
    min-height: 720px;
    /* padding: 24px;
    border-radius: 8px;
    background-color: #218CF7;
    color: #fff;
    line-height: 24px;
    letter-spacing: -0.5px;
    box-sizing: border-box; */
}

#main-series-post-detail > #content-container > h1{
    font-size: 24px;
    font-weight: bold;
}
#main-series-post-detail > #content-container > h2{
    font-size: 20px;
    font-weight: bold;
    margin: 16px 0px;
}
#main-series-post-detail > #content-container > p{
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.5px;
}
#main-series-post-detail > #content-container > strong{

}
#main-series-post-detail > #content-container > em{

}
#main-series-post-detail > #content-container > ol{

}
#main-series-post-detail > #content-container > ul{

}
#main-series-post-detail > #content-container  img{
    max-width: 100%;
    margin:8px 0px 0px 0px;
}

@media (max-width: 480px) {
    #main-series-post-detail {
        width: 100%;
        padding:24px 8px 12px 8px;
        box-sizing: border-box;
    }

    #main-series-post-detail > p{
        font-size: 16px;
        margin: 24px 0px 12px 0px;
    }
    
    #main-series-post-detail > div{
        width: 100%;
    }

    #main-series-post-detail > #content-container{
        min-height: 400px;
    }
    
    #main-series-post-detail > #content-container > h1{
        font-size: 24px;
        font-weight: bold;
    }
    #main-series-post-detail > #content-container > h2{
        font-size: 16px;
        margin: 12px 0px;
    }
    #main-series-post-detail > #content-container > p{
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.5px;
    }
    #main-series-post-detail > #content-container > strong{
    
    }
    #main-series-post-detail > #content-container > em{
    
    }
    #main-series-post-detail > #content-container > ol{
    
    }
    #main-series-post-detail > #content-container > ul{
    
    }
    #main-series-post-detail > #content-container  img{

    }
}