#seriespage{
    width: 1168px;
    margin: 0 auto;
    padding: 24px 16px 60px 16px;
    min-height: calc(100vh - 220px);
}

#seriespage > #title-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

#seriespage > #title-container > #design-line{
    width: 48px;
    height: 2px;
    background-color: #0558AA;
}

#seriespage > #title-container > p{
    font-size: 24px;
    font-weight: bold;
}

#seriespage > #series-container{
    display: grid;
    grid-template-columns: 286px 286px 286px 286px;
    gap: 16px 8px;
    margin-top: 40px;
}

@media (max-width: 480px) {

    #seriespage {
      width: 100%;
      padding: 12px 8px 40px 8px;
      box-sizing: border-box;
    }

    #seriespage > #title-container{
        gap: 4px;
    }

    #seriespage > #title-container > p{
        font-size: 16px;
    }
    
    #seriespage > #series-container{
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
        margin-top: 12px;
    }
}