#curriculumpage {
  width: 1168px;
  margin: 0 auto;
  padding: 24px 16px 40px 16px;
  min-height: calc(100vh - 220px);
  position: relative;
}

/*academic-introduction-container*/
#academic-introduction-container {
  width: 100%;
  min-height: 192px;
  background-color: #218cf7;
  color: #fff;
  padding: 24px;
  box-sizing: border-box;
}

#academic-introduction-container > p:first-of-type {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

#academic-introduction-container > p:last-of-type {
  letter-spacing: -0.5px;
  line-height: 24px;
}

/*academic-introduction-container end*/

/*academic-curriculum-container start*/
#curriculumpage > #academic-curriculum-container {
  padding-top: 24px;
}

#curriculumpage > #academic-curriculum-container > div:first-of-type {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#curriculumpage > #academic-curriculum-container > div:first-of-type > div {
  width: 32px;
  height: 2px;
  background-color: #0558aa;
}

#curriculumpage > #academic-curriculum-container > div:first-of-type > p {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/*academic-curriculum-container end*/

@media (max-width: 480px) {
  #curriculumpage {
    width: auto;
    padding: 24px 8px 40px 8px;
  }

  #academic-introduction-container > p:first-of-type {
    margin-bottom: 8px;
    font-size: 18px;
  }

  #curriculumpage {
    width: auto;
  }

  #academic-introduction-container > p:last-of-type {
    font-size: 14px;
  }

  #curriculumpage > #academic-curriculum-container > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  #curriculumpage > #academic-curriculum-container > div:first-of-type > div {
    width: 32px;
    height: 2px;
    background-color: #0558aa;
  }

  #curriculumpage > #academic-curriculum-container > div:first-of-type > p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
  }
}
